import calls from './calls'

function logError(err) {
  console.error(err)
}

function imageUrl(id) {
  return `${process.env.VUE_APP_ROOT_API}/api/media/image/${id}`
}

async function getImageObjectUrl(id) {
  const url = `/api/media/image/${id}`
  let objectUrl = null
  try {
    const response = await calls.get(url, 'blob')
    objectUrl = URL.createObjectURL(response.data)
  } catch (error) {
    logError(error)
  }

  return objectUrl
}

async function uploadImage(file, details) {
  let result = {
    id: null,
    name: '',
    message: '',
  }

  const formData = new FormData()
  formData.append('foo', file)
  formData.append('name', details.name)
  formData.append('description', details.description)
  formData.append('notes', details.notes)

  const url = '/api/media/upload/image'
  try {
    const response = await calls.post(url, formData)
    result = response.data
  } catch (error) {
    logError(error)
  }

  return result
}

export default {
  uploadImage,
  imageUrl,
  getImageObjectUrl,
}
